import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import CountryService from "@/core/services/country.service";
import UrlHandlerApiService from "@/core/services/url-handler.service";
import Vue from "vue";

export default {
  props: {},
  name: "payments-list",
  components: {},
  data() {
    return {
      BASE_URL: "",
      API_TRANSACTIONS: "/membership/api/membership/v1/Wallet/GetShopTransactions",
      selectedShop: null,
      searchShops: "",
      shops: [],
      shopsLoading: false,
      pagination: {},
      total: 10,
      activeCountryId: null,
      error: null,
      shopTransactions: [],
      transactionsLoading: false,
      dateFromMenu: false,
      dateFrom: null,
      dateToMenu: false,
      dateTo: null,
      componentLoaded: false
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.componentLoaded) return;
        this.getShopTransactions();
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.loadShopsDropDown();
    this.activeCountryId = CountryService.getActiveCountry();
    this.BASE_URL = await UrlHandlerApiService.getBaseUrl();
    this.componentLoaded = true;
  },
  methods: {
    loadShopsDropDown() {
      if (this.shopsLoading) return;

      this.shopsLoading = true;

      ApiService.query("api/shops/dropdown-list", { countryId: this.activeCountryId })
        .then(res => {
          if (!res?.data) return;
          this.shops = res.data;
        })
        .catch(error => (this.error = error))
        .finally(() => (this.shopsLoading = false));
    },
    getShopTransactions() {
      if (this.transactionsLoading) return;
      if (!this.selectedShop?.id) {
        this.resetTansactionsAndPagination();
        return;
      }

      const params = {
        shopId: this.selectedShop.id,
        page: this.pagination.page,
        pageSize: this.pagination.itemsPerPage,
        timeFrom: this.dateFrom,
        timeTo: this.dateTo
      };

      this.transactionsLoading = true;
      Vue.axios
        .get(this.BASE_URL + this.API_TRANSACTIONS, { params })
        .then(res => {
          if (!res.data?.result) return;
          this.shopTransactions = res.data.result;
          this.total = res.data.totalCount;
          // console.log("transactions", this.shopTransactions);
        })
        .catch(error => (this.error = error))
        .finally(() => (this.transactionsLoading = false));
    },
    resetTansactionsAndPagination() {
      this.shopTransactions = [];
      this.total = 0;
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      }).format(new Date(date));
    }
  },
  computed: {
    name() {
      return this.$i18n.t("menu.payments");
    },
    headers() {
      return [
        {
          text: "Date",
          align: "left",
          value: "createdAt",
          sortable: true
        },
        {
          text: "Order Item ID",
          align: "left",
          value: "orderItemId",
          sortable: true
        },
        {
          text: "Product Name",
          align: "left",
          value: "itemName",
          sortable: true
        },
        {
          text: "Quantity",
          align: "left",
          value: "quantity",
          sortable: true
        },
        {
          text: "Gross Price",
          align: "left",
          value: "grossPrice",
          sortable: true
        },
        {
          text: "Order Status",
          align: "left",
          value: "orderStatus",
          sortable: true
        },
        {
          text: "Neto Price",
          align: "left",
          value: "netPrice",
          sortable: true
        },
        {
          text: "VAT",
          align: "left",
          value: "vatAmount",
          sortable: true
        },
        {
          text: "Total",
          align: "left",
          value: "finalPrice",
          sortable: true
        }
      ];
    }
  }
};
